<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Evaluasi
    </div>
    <BTabs
      v-model="tabIndex"
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        title="Team Lead"
        :active="$route.query.tab === 'team-lead'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <TeamLead />
      </BTab>
      <BTab
        title="Talent"
        :active="$route.query.tab === 'talent'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <Talent />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import Talent from './tabs/Talent.vue'
import TeamLead from './tabs/TeamLead.vue'

export default {
  components: {
    Talent,
    TeamLead,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['team-lead', 'talent'],
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      this.$router.replace({ query: { tab } }).catch(err => {})
    },
  },
}
</script>
