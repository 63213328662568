<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-[10px] w-100 p-0 my-[24px]"
      >
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama Talent, Partner, Talent Lead"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <VSelect
          v-model="isEvaluation"
          :reduce="opt => opt.value"
          :options="filterOptions"
          placeholder="Semua"
          class="min-w-[200px]"
          @input="getListData"
        />
        <div class="w-[250px] container-date">
          <MonthlyPicker
            v-model="periode"
            :month-labels="monthlabel"
            date-format="MMM YYYY"
            :clear-option="false"
            place-holder="-"
            selected-background-color="#F95301"
            class="month-picker max-w-[100px]"
            @selected="setPeriode($event)"
          />
        </div>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th
                v-for="head in fields"
                :key="head.key"
                :class="head.class"
              >
                {{ head.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, indexList) in items">
              <b-tr
                v-for="(cell, index) in item.evaluations"
                :key="cell.id"
              >
                <b-td
                  v-if="index === 0"
                  :rowspan="item.evaluations.length"
                  class="align-top text-[#333333] text-md"
                >
                  {{ indexList + 1 }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.evaluations.length"
                  class="align-top text-[#333333]"
                >
                  <div
                    class="flex flex-col max-w-[150px]"
                  >
                    <span class="text-md">{{ item.full_name }}</span>
                    <span
                      v-b-tooltip.hover.top="item.team_lead_names"
                      class="text-sm text-muted truncate"
                    >{{ item.team_lead_names }}</span>
                  </div>
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.evaluations.length"
                  class="align-top text-[#333333] text-md"
                >
                  {{ item.average_rating.toFixed(1) }}
                </b-td>
                <b-td class="align-top text-[#333333] text-md">
                  {{ cell.partner_name }}
                </b-td>
                <b-td class="align-top text-[#333333] text-md">
                  {{ cell.rating }}
                </b-td>
                <b-td
                  v-b-tooltip.hover.top="cell.evaluation !== '-' ? cell.evaluation : '-'"
                  class="align-top text-[#333333] text-md"
                >
                  {{ cell.evaluation }}
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { DATE_NUMBER_SLASH, LABELMONTH, YEAR_MONTH_DAY } from '@/libs/filterDate'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import MonthlyPicker from 'vue-monthly-picker'
import {
  today,
  last2,
  last7,
  firstDateOfMonth,
} from '@/store/helpers'
import {
  tableTalent,
  filterOptions,
} from '../config'

export default {
  components: {
    VSelect,
    MonthlyPicker,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableTalent,
      idItem: 0,
      dateRange: {},
      alertError,
      DATE_NUMBER_SLASH,
      today,
      last2,
      last7,
      periode: null,
      firstDateOfMonth,
      monthlabel: LABELMONTH,
      isEvaluation: null,
      filterOptions,
    }
  },
  mounted() {
    const defaultDate = new Date()
    const year = defaultDate.getFullYear()
    const month = defaultDate.getMonth()

    this.periode = YEAR_MONTH_DAY(new Date(year, month - 1))

    this.dateRange.startDate = new Date(year, month - 1, 1)
    this.dateRange.endDate = new Date(year, month, 0)

    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&type=talent&keyword=${this.keyword}&is_evaluation=${this.isEvaluation}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
      const url = `v2/evaluations?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&type=talent&keyword=${this.keyword}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
        const url = `v2/evaluations?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    setPeriode(val) {
      const defaultDate = new Date(YEAR_MONTH_DAY(this.periode))
      const year = defaultDate.getFullYear()
      const month = defaultDate.getMonth()

      this.dateRange.startDate = new Date(year, month, 1)
      this.dateRange.endDate = new Date(year, month + 1, 0)

      this.getListData()
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
  },

}
</script>

<style lang="scss">
.evaluation:hover {
  color: #F95031;
  cursor: pointer;
}
.month-picker .input, .month-picker .month-picker-wrapper, .month-picker .month-year-label {
  height: 100% !important;
}
.month-picker .vue-monthly-picker .month-picker-wrapper {
  min-width: 150px !important;
}
.evaluation {
  width: fit-content;
}
.container-date > div, .container-date > div.vue-daterange-picker > div.form-control.reportrange-text, .form-control, .reportrange-text {
  height: 100% !important;
}
</style>
