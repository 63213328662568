// eslint-disable-next-line import/prefer-default-export
export const tableTeamLead = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'full_name',
    label: 'Nama Talent Lead',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'average_rating',
    label: 'Average Rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'evaluation',
    label: 'evaluasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const tableTalent = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      color: 'black',
      background: '#F4F4F4',
      fontSize: '14px',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
  {
    key: 'average_rating',
    label: 'Average Rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
  {
    key: 'partner_name',
    label: 'nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
  {
    key: 'rating',
    label: 'rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
  {
    key: 'evaluation',
    label: 'evaluasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'capitalize text-[#333333] text-xl',
  },
]

export const filterOptions = [
  {
    label: 'Dengan Evaluasi',
    value: true,
  },
  {
    label: 'Tanpa Evaluasi',
    value: false,
  },
]
